import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import axios from "axios";
import MDBadge from "components/MDBadge";
import { Link, useNavigate  } from "react-router-dom";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const Index = () => {
  const [companies, setCompanies] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchCompanies();
  }, []);


  const fetchCompanies = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      // console.log("token=============",accessToken);
      const response = await axios
          .get(
            `${process.env.REACT_APP_API_URL}/company`,
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          )
      // const response = await axios.get(`${process.env.REACT_APP_API_URL}/company`);
      setCompanies(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        console.error('401 Error-------');
        localStorage.clear();
        navigate('/auth/login');
      }
      console.error('There was an error fetching the companies!', error);
    }
  };

  const handleDelete = (id) => {
    console.log('Deleting company with id:', id);
    const accessToken = localStorage.getItem("access_token");
    axios.delete(
      `${process.env.REACT_APP_API_URL}/company/${id}`,
      {
        headers: {
          Authorization: `${accessToken}`,
        },
      }
    )
    // axios.delete(`${process.env.REACT_APP_API_URL}/company/${id}`)
    
      .then(response => {
        if (response.status === 200) {
          setCompanies(prevCompanies => prevCompanies.filter(company => company._id !== id));
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          console.error('401 Error-------');
          localStorage.clear();
          navigate('/auth/login');
        }
        console.error('There was an error deleting the company!', error);
      });
  };

  const mapCompanyData = (companies) => {
    return companies.map((company) => ({
      name: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {company.name}
        </MDTypography>
      ),
      email: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {company.email}
        </MDTypography>
      ),
      phone: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {company.phone}
        </MDTypography>
      ),
      isActive: (
        <MDBox ml={-1}>
          <MDBadge badgeContent={company.isActive ? "Active" : "Inactive"} color="success" variant="gradient" size="sm" />
        </MDBox>
      ),
      action: (
        <MDBox display="flex" justifyContent="space-between">
          <Tooltip title="Edit" arrow>
            <Link to={`/company-edit/${company._id}`}>
              <IconButton aria-label="edit" size="small" color="primary">
                <EditIcon sx={{ color: "orange" }} /> {/* Orange color for EditIcon */}
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <IconButton aria-label="delete" size="small" color="error" onClick={() => handleDelete(company._id)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    }));
  };

  const columns = [
    { Header: "Name", accessor: "name", width: "30%", align: "left" },
    { Header: "Email", accessor: "email", align: "left" },
    { Header: "Phone", accessor: "phone", align: "center" },
    { Header: "Status", accessor: "isActive", align: "center" },
    { Header: "Action", accessor: "action", align: "center" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Company Details
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {companies.length > 0 ? (
                  <DataTable
                    table={{ columns, rows: mapCompanyData(companies) }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDTypography variant="h6" color="info">No companies found</MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default Index;
