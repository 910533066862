// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Switch from "@mui/material/Switch"; // Import Switch component

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate  } from "react-router-dom";

// Toast notifications
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import toastify CSS

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import LockOpenIcon from "@mui/icons-material/LockOpen";

const Index = () => {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    fetchUsers();
  }, []);


  const fetchUsers = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      // console.log("token=============",accessToken);
      const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/auth/users`,
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          );
      // const response = await axios.get(`${process.env.REACT_APP_API_URL}/auth/users`);

      setUsers(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        console.error('401 Error-------');
        localStorage.clear();
        navigate('/auth/login');
      }
      console.error('There wassssss an error fetching the users!', error);
    }
  };

  const handleDelete = (id) => {
    console.log('Deleting user with id:', id);
    const accessToken = localStorage.getItem("access_token");
    axios.delete(
          `${process.env.REACT_APP_API_URL}/auth/delete-user/${id}`,
          {
            headers: {
              Authorization: `${accessToken}`,
            },
          }
        )
    // axios.delete(`${process.env.REACT_APP_API_URL}/auth/delete-user/${id}`)
      .then(response => {
        if (response.status === 200) {
          setUsers(prevUsers => prevUsers.filter(user => user._id !== id));
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          console.error('401 Error-------');
          localStorage.clear();
          navigate('/auth/login');
        }
        console.error('There was an error deleting the user!', error);
      });
  };

  const handleToggle = async (id, currentStatus) => {
    try {
      const updatedUser = { isActive: !currentStatus };
      const accessToken = localStorage.getItem("access_token");
      const response = await axios.put(
            `${process.env.REACT_APP_API_URL}/auth/update-user/${id}`,
            updatedUser,
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          );
      // const response = await axios.put(`${process.env.REACT_APP_API_URL}/auth/update-user/${id}`, updatedUser);

      if (response.status === 200) {
        // Update the users state to reflect the new isActive status
        setUsers(prevUsers =>
          prevUsers.map(user => (user._id === id ? { ...user, isActive: !currentStatus } : user))
        );
        
        // Show success toast
        toast.success(`User status changed to ${!currentStatus ? 'Active' : 'Inactive'}`);
      } 
      
    } catch (error) {
      if (error.response.status === 401) {
        console.error('401 Error-------');
        localStorage.clear();
        navigate('/auth/login');
      }
      console.error('There was an error updating the user status!', error);
    }
  };

  const mapUserData = (users) => {
    return users.map((user) => ({
      username: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {user.username}
        </MDTypography>
      ),
      email: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {user.email}
        </MDTypography>
      ),
      companyId: (
        <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
          {user.companyId}
        </MDTypography>
      ),
      isActive: (
        <MDBox ml={-1}>
          <Switch
            checked={user.isActive}
            onChange={() => handleToggle(user._id, user.isActive)}
            color={user.isActive ? "primary" : "default"} // Blue for Active, Gray for Inactive
          />
        </MDBox>
      ),
      action: (
        <MDBox display="flex" justifyContent="space-between">
          <Tooltip title="Edit" arrow>
            <Link to={`/user-edit/${user._id}`} style={{ textDecoration: 'none' }}>
              <IconButton aria-label="edit" size="small" color="primary">
                <EditIcon sx={{ color: "orange" }} /> {/* Orange color for EditIcon */}
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Change Password" arrow>
            <Link to={`/user-change-password/${user._id}`} style={{ textDecoration: 'none' }}>
              <IconButton aria-label="change password" size="small" color="secondary">
                <LockOpenIcon sx={{ color: "blue" }} /> {/* Blue color for LockOpenIcon */}
              </IconButton>
            </Link>
          </Tooltip>
          <Tooltip title="Delete" arrow>
            <IconButton aria-label="delete" size="small" color="error" onClick={() => handleDelete(user._id)}>
              <DeleteIcon sx={{ color: "red" }} /> {/* Red color for DeleteIcon */}
            </IconButton>
          </Tooltip>
        </MDBox>
      ),
    }));
  };

  const columns = [
    { Header: "Username", accessor: "username", width: "30%", align: "left" },
    { Header: "Email", accessor: "email", width: "25%", align: "left" },
    { Header: "Company ID", accessor: "companyId", width: "20%", align: "center" },
    { Header: "Status", accessor: "isActive", width: "10%", align: "center" },
    { Header: "Action", accessor: "action", width: "15%", align: "center" },  // Increased width for "Action" column
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  User Details
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                {users.length > 0 ? (
                  <DataTable
                    table={{ columns, rows: mapUserData(users) }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                  />
                ) : (
                  <MDTypography variant="h6" color="info">No users found</MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <ToastContainer /> {/* ToastContainer for toast messages */}
    </DashboardLayout>
  );
};

export default Index;
