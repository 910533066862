import React, { useState } from 'react';
import axios from 'axios';

const CompanyForm = ({ onCreate }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    isActive: true,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`${process.env.REACT_APP_API_URL}/compny`, formData)
      .then(response => {
        onCreate(response.data);
        setFormData({ name: '', email: '', phone: '', address: '', isActive: true });
      })
      .catch(error => {
        if (error.response.status === 401) {
          console.error('401 Error-------');
          localStorage.clear();
          navigate('/auth/login');
        }
        console.error('There was an error creating the company!', error);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>Create New Company</h2>
      <div>
        <label>Name:</label>
        <input type="text" name="name" value={formData.name} onChange={handleChange} required />
      </div>
      <div>
        <label>Email:</label>
        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
      </div>
      <div>
        <label>Phone:</label>
        <input type="text" name="phone" value={formData.phone} onChange={handleChange} required />
      </div>
      <div>
        <label>Address:</label>
        <input type="text" name="address" value={formData.address} onChange={handleChange} required />
      </div>
      <div>
        <label>Is Active:</label>
        <input type="checkbox" name="isActive" checked={formData.isActive} onChange={() => setFormData({ ...formData, isActive: !formData.isActive })} />
      </div>
      <button type="submit">Create</button>
    </form>
  );
};

export default CompanyForm;
