import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const ChangePassword = () => {
  const { id } = useParams(); // Get the user id from the route parameters
  const [formData, setFormData] = useState({
    email: '',
    newPassword: '',
    confirmPassword: '', // Add confirmPassword field
  });
  const [errors, setErrors] = useState({});
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data and set the email
    const accessToken = localStorage.getItem("access_token");
    axios.get(
            `${process.env.REACT_APP_API_URL}/auth/get-user/${id}`,
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          )
    // axios.get(`${process.env.REACT_APP_API_URL}/auth/get-user/${id}`)
      .then(response => {
        if (response.data) {
          setFormData(prevState => ({
            ...prevState,
            email: response.data.email, // Set the fetched email
          }));
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
          console.error('401 Error-------');
          localStorage.clear();
          navigate('/auth/login');
        }
        console.error('There was an error fetching the user data!', error);
      });
  }, [id]);

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.newPassword) {
      formErrors.passwordError = "The new password cannot be null";
      isValid = false;
    }

    if (formData.newPassword !== formData.confirmPassword) {
      formErrors.confirmPasswordError = "New Password and Confirm Password do not match";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const accessToken = localStorage.getItem("access_token");
      axios.put(
            `${process.env.REACT_APP_API_URL}/auth/admin-update-user-password`,
            formData,
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          )
      // axios.put(`${process.env.REACT_APP_API_URL}/auth/admin-update-user-password`, formData)
        .then(response => {
          if (response.status === 200) {
            setSuccessMessage('Password successfully updated!');
            setErrorMessage('');
            setTimeout(() => {
              navigate('/user-list'); // Navigate to /user-list after success
            }, 1000); // Redirect after 1 second
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            console.error('401 Error-------');
            localStorage.clear();
            navigate('/auth/login');
          }
          if (error.response && error.response.status === 400) {
            setErrorMessage(error.response.data.message || 'An error occurred. Please try again.');
          } else {
            console.error('There was an error updating the password!', error);
          }
        });
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Change User Password
                </MDTypography>
              </MDBox>

              <MDBox p={3}>
                <form onSubmit={handleSubmit}>
                  <MDBox display="flex" flexDirection="column" mb={3}>
                    <MDBox mb={2} width="100%">
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Email
                      </MDTypography>
                      <MDInput
                        type="email"
                        fullWidth
                        name="email"
                        value={formData.email}
                        InputProps={{
                          readOnly: true, // Make the field read-only
                        }}
                      />
                    </MDBox>
                    <MDBox mb={2} width="100%">
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        New Password
                      </MDTypography>
                      <MDInput
                        type="password"
                        fullWidth
                        name="newPassword"
                        value={formData.newPassword}
                        onChange={handleChange}
                        error={Boolean(errors.passwordError)}
                      />
                      {errors.passwordError && (
                        <MDTypography variant="caption" color="error" fontWeight="light">
                          {errors.passwordError}
                        </MDTypography>
                      )}
                    </MDBox>
                    {/* Confirm Password Field */}
                    <MDBox mb={2} width="100%">
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                        Confirm Password
                      </MDTypography>
                      <MDInput
                        type="password"
                        fullWidth
                        name="confirmPassword"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        error={Boolean(errors.confirmPasswordError)}
                      />
                      {errors.confirmPasswordError && (
                        <MDTypography variant="caption" color="error" fontWeight="light">
                          {errors.confirmPasswordError}
                        </MDTypography>
                      )}
                    </MDBox>
                  </MDBox>

                  <MDBox mt={4} display="flex" justifyContent="end">
                    <MDButton variant="gradient" color="info" type="submit">
                      Change Password
                    </MDButton>
                  </MDBox>
                </form>

                {successMessage && (
                  <MDBox mt={3}>
                    <MDTypography variant="h6" color="success">
                      {successMessage}
                    </MDTypography>
                  </MDBox>
                )}

                {errorMessage && (
                  <MDBox mt={3}>
                    <MDTypography variant="h6" color="error">
                      {errorMessage}
                    </MDTypography>
                  </MDBox>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ChangePassword;
