import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

const CompanyUpdate = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [company, setCompany] = useState({
    name: "",
    email: "",
    phone: "",
    address: "", // Add the address field in the state
    subdomain: "",
    isActive: false,
  });

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  const fetchCompanyDetails = async () => {
    try {
      const accessToken = localStorage.getItem("access_token");
      // console.log("token=============",accessToken);
      const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/company/${id}`,
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          );

      // const response = await axios.get(
      //   `${process.env.REACT_APP_API_URL}/company/${id}`
      // );
      setCompany(response.data);
    } catch (error) {
      if (error.response.status === 401) {
        console.error('401 Error-------');
        localStorage.clear();
        navigate('/auth/login');
      }
      console.error("There was an error fetching the company details!", error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCompany((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCompany((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const accessToken = localStorage.getItem("access_token");
      // console.log("token=============",accessToken);
      await axios.put(
            `${process.env.REACT_APP_API_URL}/company/${id}`,
            company,
            {
              headers: {
                Authorization: `${accessToken}`,
              },
            }
          );
      // await axios.put(`${process.env.REACT_APP_API_URL}/company/${id}`, company);
      navigate("/company-list"); // Redirect to company list after successful update
    } catch (error) {
      if (error.response.status === 401) {
        console.error('401 Error-------');
        localStorage.clear();
        navigate('/auth/login');
      }
      console.error("There was an error updating the company!", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Edit Company
                </MDTypography>
              </MDBox>
              <MDBox p={3}>
                <form onSubmit={handleSubmit}>
                  <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      width="100%"
                      mr={2}
                    >
                      <MDTypography
                        variant="body2"
                        color="text"
                        ml={1}
                        fontWeight="regular"
                      >
                        Name
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="name"
                          value={company.name}
                          onChange={handleInputChange}
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      width="100%"
                      ml={2}
                    >
                      <MDTypography
                        variant="body2"
                        color="text"
                        ml={1}
                        fontWeight="regular"
                      >
                        Email
                      </MDTypography>
                      <MDBox mb={1} width="100%">
                        <MDInput
                          fullWidth
                          type="email"
                          name="email"
                          value={company.email}
                          onChange={() => {}} // Disable the onChange event handler
                          InputProps={{
                            readOnly: true, // Make the field read-only
                          }}
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" flexDirection="row" mt={5} mb={3}>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      width="100%"
                      mr={2}
                    >
                      <MDTypography
                        variant="body2"
                        color="text"
                        ml={1}
                        fontWeight="regular"
                      >
                        Phone
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="phone"
                          value={company.phone}
                          onChange={handleInputChange}
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      width="100%"
                      ml={2}
                    >
                      <MDTypography
                        variant="body2"
                        color="text"
                        ml={1}
                        fontWeight="regular"
                      >
                        Address
                      </MDTypography>
                      <MDBox mb={1} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="address" // Set the name attribute to "address"
                          value={company.address} // Bind the value to the company's address
                          onChange={handleInputChange} // Handle the change event
                        />
                      </MDBox>
                    </MDBox>
                  </MDBox>

                  <MDBox display="flex" flexDirection="row" mt={3} mb={3}>
                    <MDBox display="flex" flexDirection="column" alignItems="flex-start" width="100%" mr={2}>
                      <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                            Subdomain
                      </MDTypography>
                      <MDBox mb={2} width="100%">
                        <MDInput
                          fullWidth
                          type="text"
                          name="subdomain"
                          value={company.subdomain}
                          onChange={() => {}} // Disable the onChange event handler
                          InputProps={{
                            readOnly: true, // Make the field read-only
                          }}
                        />
                      </MDBox>
                    </MDBox>
                    <MDBox
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            width="100%"
                            ml={2}
                            mt={5}
                        >
                          <MDTypography variant="body2" color="text" ml={1} fontWeight="regular">
                            Is Active
                          </MDTypography>
                          <MDBox ml={1}>
                    <label>
                      <input
                        type="checkbox"
                        name="isActive"
                        checked={company.isActive}
                        onChange={handleCheckboxChange}
                      />
                    </label>
                  </MDBox>
                  </MDBox>
                  </MDBox>

                  
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                    >
                      Update Company
                    </MDButton>
                  </MDBox>
                </form>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default CompanyUpdate;
